import { createSlice } from "@reduxjs/toolkit";
import https from "src/utils/http";
import { errorHandler } from "src/utils/errorHandler";

const BASE_URL = import.meta.env.VITE_BASE_URL;

const adminSlice = createSlice({
    name: "admin",
    initialState: {
        calcData: null,
        calcStatus: null,
        loadingCalcStatus: false,
        updatingPriority: false,
        error: null,
        updateSuccess: false,
    },
    reducers: {
        resetUpdateSuccess: (state) => {
            state.updateSuccess = false;
        },
        setLoadingCalcStatus(state, { payload }) {
            state.loadingCalcStatus = payload;
        },
        setCalcData(state, { payload }) {
            state.calcData = payload;
        },
        setUpdatingPriority(state, { payload }) {
            state.updatingPriority = payload;
        },
        setUpdateSuccess(state, { payload }) {
            state.updateSuccess = payload;
        },
    },
});

export const {
    setLoadingCalcStatus,
    resetUpdateSuccess,
    setCalcData,
    setUpdatingPriority,
    setUpdateSuccess,
} = adminSlice.actions;

export function fetchCalcStatus() {
    return async (dispatch) => {
        await dispatch(setLoadingCalcStatus(true));
        try {
            const response = await https.get(
                `${BASE_URL}/api/v1/admin/bamAdmin/getCalcStatus`
            );
            dispatch(setCalcData(response.data));
        } catch (error) {
            return errorHandler(error);
        } finally {
            dispatch(setLoadingCalcStatus(false));
        }
    };
}

export function updateReportCalcPriority({ changeId, priority }) {
    return async (dispatch) => {
        await dispatch(setUpdatingPriority(true));
        try {
            const response = await https.post(
                `${BASE_URL}/api/v1/admin/bamAdmin/updateReportCalcPriority`,
                {
                    changeId,
                    priority,
                }
            );
            dispatch(setCalcData(response.data));
            dispatch(setUpdateSuccess(true));
        } catch (error) {
            dispatch(setUpdateSuccess(false));
            return errorHandler(error);
        } finally {
            dispatch(setUpdatingPriority(false));
        }
    };
}

export default adminSlice.reducer;
