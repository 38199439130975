import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authenticationReducer from "../routes/AuthenticationRoute/AuthenticationSlice";
import dashboardReducer from "../routes/DashboardRoute/DashboardSlice";
import ratiosReducer from "../routes/RatiosRoute/RatiosSlice";
import reportsReducer, {
    reportChange,
} from "../routes/ReportsRoute/ReportsSlice";
import peersReducer from "../routes/PeerSelectionRoute/PeersSlice";
import customReportReducer from "../routes/addToReportMenu/customReportsSlice";
import addToReportsSlice from "../routes/addToReportMenu/addToReportsSlice";
import marketReducer from "../routes/MarketRoute/marketSlice";
import loadingReducer from "./loading";
import adminReducer from "../routes/AdminPanel/AdminState/adminSlice";

export default configureStore({
    reducer: {
        authentication: authenticationReducer,
        dashboard: dashboardReducer,
        ratios: ratiosReducer,
        reports: reportsReducer,
        peers: peersReducer,
        customReport: customReportReducer,
        addToReports: addToReportsSlice,
        loading: loadingReducer,
        market: marketReducer,
        admin: adminReducer,
    },
    middleware: [
        ...getDefaultMiddleware({
            serializableCheck: false,
        }),
        reportChange,
    ],
});
