// src/fonts/open-sans/OpenSans-Regular.ttf and other variant files should exist in this directory
import OpenSansLight from "../fonts/open-sans/OpenSans-Light.ttf";
import OpenSansLightItalic from "../fonts/open-sans/OpenSans-LightItalic.ttf";
import OpenSansRegular from "../fonts/open-sans/OpenSans-Regular.ttf";
import OpenSansItalic from "../fonts/open-sans/OpenSans-Italic.ttf";
import OpenSansBold from "../fonts/open-sans/OpenSans-Bold.ttf";
import OpenSansBoldItalic from "../fonts/open-sans/OpenSans-BoldItalic.ttf";
import OpenSansExtraBold from "../fonts/open-sans/OpenSans-ExtraBold.ttf";
import OpenSansExtraBoldItalic from "../fonts/open-sans/OpenSans-ExtraBoldItalic.ttf";

const unicodeRange =
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF";

const openSansLight = {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 300,
    src: `url(${OpenSansLight}) format('truetype')`,
    unicodeRange,
};

const openSansLightItalic = {
    fontFamily: "Open Sans",
    fontStyle: "italic",
    fontDisplay: "swap",
    fontWeight: 300,
    src: `url(${OpenSansLightItalic}) format('truetype')`,
    unicodeRange,
};

const openSansRegular = {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `url(${OpenSansRegular}) format('truetype')`,
    unicodeRange,
};

const openSansItalic = {
    fontFamily: "Open Sans",
    fontStyle: "italic",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `url(${OpenSansItalic}) format('truetype')`,
    unicodeRange,
};

const openSansBold = {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 700,
    src: `url(${OpenSansBold}) format('truetype')`,
    unicodeRange,
};

const openSansBoldItalic = {
    fontFamily: "Open Sans",
    fontStyle: "italic",
    fontDisplay: "swap",
    fontWeight: 700,
    src: `url(${OpenSansBoldItalic}) format('truetype')`,
    unicodeRange,
};

const openSansExtraBold = {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 800,
    src: `url(${OpenSansExtraBold}) format('truetype')`,
    unicodeRange,
};

const openSansExtraBoldItalic = {
    fontFamily: "Open Sans",
    fontStyle: "italic",
    fontDisplay: "swap",
    fontWeight: 800,
    src: `url(${OpenSansExtraBoldItalic}) format('truetype')`,
    unicodeRange,
};

export const fonts = [
    openSansLight,
    openSansLightItalic,
    openSansRegular,
    openSansItalic,
    openSansBold,
    openSansBoldItalic,
    openSansExtraBold,
    openSansExtraBoldItalic,
];

export const fontFaces = [
    openSansLight,
    openSansLightItalic,
    openSansRegular,
    openSansItalic,
    openSansBold,
    openSansBoldItalic,
    openSansExtraBold,
    openSansExtraBoldItalic,
];
