import _ from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import Navigation from "components/@bank-ui/core/Navigation";
import Logo from "components/@bank-ui/core/Logo";
import User from "components/User/User";
import { ReactComponent as QuestionCircleIcon } from "assets/icons/icon-help.svg";
import { getAuthenticatedUser } from "routes/AuthenticationRoute/AuthenticationSlice";
import HeaderSearchBar from "components/global/HeaderSearchBar";
import { AppBar, IconButton, Toolbar, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FlexWrapper } from "components/@bank-ui/core/Wrapper/FlexWrapper";
import clsx from "clsx";
import { PATHS_BA, PATHS_CUA } from "constants/paths";

export const ActiveIndicator = styled.div`
    height: 6px;
    width: 56px;
    background-color: ${(p) => p.theme.palette.accent.blueDark};
    position: absolute;
    bottom: 0;
`;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        "@media print": {
            display: "none",
        },
    },
    appBar: {
        height: 68,
        backgroundColor: theme.palette.background.paper,
        boxShadow: "none",
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolBar: {
        height: 68,
    },
    userProfile: {
        display: "inline-flex",
        alignItems: "center",
        marginLeft: 8,
    },
    helpIcon: {
        fill: theme.palette.accent.blueDark,
        height: 32,
        width: 32,
    },
    altToolBar: {
        height: 68,
        display: "flex",
        alignItems: "center",
    },
    toolBar__print: {
        alignItems: "center",
        justifyContent: "center",
        display: "none",
        "@media print": {
            height: 68,
            display: "flex",
            "& img": {
                height: "60px !important",
            },
        },
    },
}));

export default function Header() {
    const { pathname } = useLocation();
    const history = useHistory();
    const { firstName, lastName } = useSelector((state) =>
        getAuthenticatedUser(state)
    );

    const primaryPaths =
        pathname === PATHS_BA.HOME || pathname === PATHS_CUA.HOME;

    const isAdminPath = pathname.includes("admin");

    const classes = useStyles();

    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    const handleLogoClick = () => {
        history.push(PATHS_BA.HOME);
    };

    return (
        <>
            <div className={classes.root}>
                <AppBar
                    className={clsx("primary__header", classes.appBar)}
                    position="fixed"
                >
                    <Toolbar className={clsx("toolBar", classes.toolBar)}>
                        {isAdminPath ? (
                            <IconButton onClick={handleLogoClick} disableRipple>
                                <Logo showIcon={true} showTitle={false} />
                            </IconButton>
                        ) : (
                            <Logo
                                showIcon={true}
                                showTitle={false}
                                style={{
                                    marginRight: 24,
                                    flexGrow: primaryPaths ? 1 : 0,
                                }}
                            />
                        )}
                        {!primaryPaths && (
                            <FlexWrapper
                                className="primary__section"
                                style={{ flexGrow: 1, height: "100%" }}
                            >
                                {!isAdminPath && (
                                    <>
                                        <div
                                            style={{
                                                flexGrow: 1,
                                                height: "100%",
                                                minWidth: "380px",
                                            }}
                                        >
                                            <Navigation />
                                        </div>
                                        <HeaderSearchBar />
                                    </>
                                )}
                            </FlexWrapper>
                        )}

                        <div
                            className={clsx(
                                "user__section",
                                classes.userProfile
                            )}
                        >
                            <IconButton
                                color="primary"
                                onClick={() =>
                                    openInNewTab(
                                        "https://virtualtrainer.fiservapps.com/TrainGuides/BankIntelligence/Content/Home.htm?tocpath=_____1"
                                    )
                                }
                                size="large"
                            >
                                <QuestionCircleIcon
                                    className={classes.helpIcon}
                                />
                            </IconButton>
                            <User
                                userInfo={_.assign({}, { firstName, lastName })}
                            />
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
            <div className={clsx("toolbar__print", classes.toolBar__print)}>
                <Logo showIcon={true} showTitle={false} />
            </div>
        </>
    );
}
