import axios from "axios";
import { generateCodeChallenge, generateCodeVerifier } from "./oauth2-verifier";
import { OAuth2Config } from "./oauth2-config";

const OAuth2Service = {
    handleLogin: function () {
        let codeVerifier =
            localStorage.getItem("codeVerifier") || generateCodeVerifier();
        const codeChallenge = generateCodeChallenge(codeVerifier);

        var loginURL = new URL(OAuth2Config.authUrl);
        loginURL.searchParams.append("client_id", OAuth2Config.authClientId);
        loginURL.searchParams.append("response_type", "code");
        loginURL.searchParams.append("redirect_uri", OAuth2Config.callbackUrl);
        loginURL.searchParams.append("response_mode", "query");
        loginURL.searchParams.append("scope", "openid email profile");
        loginURL.searchParams.append("code_challenge", codeChallenge);
        loginURL.searchParams.append(
            "code_challenge_method",
            OAuth2Config.challengeMethod
        );
        if (OAuth2Config.identityProvider !== "") {
            loginURL.searchParams.append(
                "identity_provider",
                OAuth2Config.identityProvider
            );
        }

        window.location.assign(loginURL.toString());
    },

    handleCallback: async function (tokenCode) {
        const codeVerifier = localStorage.getItem("codeVerifier");
        let data = JSON.stringify({
            providerId: "Ping",
            tokenCode: tokenCode,
            codeVerifier: codeVerifier,
            redirectUri: OAuth2Config.callbackUrl,
        });

        let config = {
            method: "post",
            url: OAuth2Config.accessTokenUrl,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        return await axios.request(config);
    },

    handleRefresh: async function (refreshToken, scope, idToken) {
        let data = JSON.stringify({
            refresh_token: refreshToken,
            scope: scope,
        });

        let config = {
            method: "post",
            url: OAuth2Config.refreshTokenUrl,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + idToken,
            },
            data: data,
        };

        return await axios.request(config);
    },

    handleLogout: async function (callback) {
        try {
            // Perform the redirect
            var signoutUrl = new URL(OAuth2Config.logoutUrl);
            signoutUrl.searchParams.append(
                "post_logout_redirect_uri",
                OAuth2Config.logoutCallback
            );
            window.location.assign(signoutUrl);
        } catch (error) {
            console.error("Error during logout: ", error);
        } finally {
            if (typeof callback === "function") {
                callback();
            }
        }
    },
};

export const oauth2Service = OAuth2Service;
