import React, { useState, useEffect, forwardRef } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Stepper,
    Step,
    StepLabel,
    Box,
    Typography,
    Divider,
    Grow,
} from "@mui/material";
import { styled } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import StepConnector from "@mui/material/StepConnector";
import InfoIcon from "@mui/icons-material/Info";
import SecurityIcon from "@mui/icons-material/Security";
import SettingsIcon from "@mui/icons-material/Settings";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import IntroductionImage from "./images/introduction.svg";
import Why2FAImage from "./images/why-2fa.svg";
import Setup2FAImage from "./images/setup-2fa.svg";
import ConfirmationImage from "./images/confirmation.svg";

// Steps and their corresponding content and icons
const steps = [
    { label: "Introduction", icon: InfoIcon, image: IntroductionImage },
    {
        label: "Why 2-Factor Authentication?",
        icon: SecurityIcon,
        image: Why2FAImage,
    },
    {
        label: "What to Expect?",
        icon: SettingsIcon,
        image: Setup2FAImage,
    },
    {
        label: "Additional Info",
        icon: CheckCircleIcon,
        image: ConfirmationImage,
    },
];

const stepContent = [
    "We have made significant enhancements to our login process to improve security and better protect your account. Please review the following information.",
    "2-Factor Authentication (2FA) adds an additional layer of security by requiring a second form of verification, such as a code sent to your email, in addition to your new password.",
    "Your prior password is no longer valid. A temporary password has been sent to your registered email. Use this password to access your account and then promptly update it to a new password of your choice.",
    "Your security is our priority. For any issues logging in, please click 'Trouble Signing On?' for assistance or contact our support team at BI.Support@fiserv.com.",
];

// Custom styling for StepConnector
const QontoConnector = styled(StepConnector)(({ theme }) => ({
    alternativeLabel: {
        top: 22,
    },
    active: {
        "& .MuiStepConnector-line": {
            backgroundImage:
                "linear-gradient( 136deg, #3B82F6 0%, #3B82F6 50%, #3B82F6 100%)",
        },
    },
    completed: {
        "& .MuiStepConnector-line": {
            backgroundImage:
                "linear-gradient( 136deg, #3B82F6 0%, #3B82F6 50%, #3B82F6 100%)",
        },
    },
    line: {
        borderColor: theme.palette.divider,
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const CustomStepIcon = (props) => {
    const { active, completed, icon } = props;
    const icons = {
        1: <InfoIcon />,
        2: <SettingsIcon />,
        3: <SecurityIcon />,
        4: <CheckCircleIcon />,
    };

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 24,
                height: 24,
                borderRadius: "50%",
                backgroundColor: completed
                    ? "#3B82F6"
                    : active
                    ? "#3B82F6"
                    : "#E0E0E0",
                color: "#fff",
                transition: "background-color 0.3s ease",
            }}
        >
            {completed ? <CheckCircleIcon /> : icons[String(icon)]}
        </div>
    );
};

const theme = createTheme({
    typography: {
        fontFamily: "'Open Sans', sans-serif",
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    textTransform: "none",
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                        backgroundColor: "#3B82F6",
                    },
                },
                outlinedSecondary: {
                    color: "#3B82F6",
                    borderColor: "#3B82F6",
                    "&:hover": {
                        borderColor: "#3B82F6",
                        backgroundColor: "rgba(59, 130, 246, 0.04)",
                    },
                },
            },
        },
    },
});

const Transition = forwardRef((props, ref) => {
    return <Grow ref={ref} {...props} />;
});

const MultiStepDialog = ({ open, handleClose }) => {
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleFinish = () => {
        setActiveStep(0);
        handleClose();
    };

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
                TransitionComponent={Transition}
                PaperProps={{
                    sx: {
                        p: 3,
                        borderRadius: 3,
                        boxShadow: 24,
                        bgcolor: "background.paper",
                        position: "relative",
                        width: "35rem",
                        height: "37rem", // Increased height
                        overflow: "hidden",
                    },
                }}
                Backdrop={{
                    sx: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        textAlign: "center",
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                    }}
                >
                    Improved Login Experience
                </DialogTitle>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        height: "100%",
                    }}
                >
                    <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                        connector={<QontoConnector />}
                        sx={{ mb: 4, width: "100%" }}
                    >
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel
                                    StepIconComponent={(props) => (
                                        <CustomStepIcon
                                            {...props}
                                            icon={index + 1}
                                        />
                                    )}
                                >
                                    {step.label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box
                        sx={{
                            textAlign: "center",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img
                            src={steps[activeStep].image}
                            alt={steps[activeStep].label}
                            style={{
                                width: "8rem",
                                height: "auto",
                                marginBottom: "1rem",
                                borderRadius: "8px",
                                transition: "transform 0.3s ease-in-out",
                            }}
                        />
                        <Typography
                            variant="body1"
                            sx={{
                                mb: 2,
                                fontWeight: "normal",
                                fontSize: "1rem",
                                color: "#3c3c3c",
                            }}
                        >
                            {stepContent[activeStep]}
                        </Typography>
                    </Box>
                </DialogContent>
                <Divider />
                <DialogActions sx={{ justifyContent: "space-between", mt: 2 }}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleClose}
                        sx={{ textTransform: "none" }}
                        disableRipple
                    >
                        Close
                    </Button>
                    <Box>
                        <Button
                            variant="outlined"
                            color="secondary"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1, textTransform: "none" }}
                            disableRipple
                        >
                            Back
                        </Button>
                        {activeStep === steps.length - 1 ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleFinish}
                                sx={{ textTransform: "none" }}
                                disableRipple
                            >
                                Finish
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                sx={{ textTransform: "none" }}
                                disableRipple
                            >
                                Next
                            </Button>
                        )}
                    </Box>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default MultiStepDialog;
